import { Button } from '@mui/material';
import { GroupResponse } from '../../services/DATA_MODELS';
import { useNavigate } from 'react-router-dom';
import './groupList.css';

interface GroupListProps {
  groups: GroupResponse[];
}

const GroupList: React.FC<GroupListProps> = ({ groups }) => {
  const navigate = useNavigate();
  return (
    <div className="container">
      {groups.map((group) => (
        <Button
          className="groupsButton"
          variant="contained"
          key={group.groupId}
          onClick={() => {
            navigate(`/new-lesson/${group.groupId}`);
          }}
        >
          {group.name}
        </Button>
      ))}
    </div>
  );
};

export default GroupList;
