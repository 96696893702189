import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LessonResponse, LessonWorkResponse } from '../../services/DATA_MODELS';

interface LessonState {
  lessonsData: LessonResponse[];
  work: LessonWorkResponse[];
}

const initialState: LessonState = {
  lessonsData: [],
  work: [],
};

export const lessonsSlice = createSlice({
  name: 'lessons',
  initialState,
  reducers: {
    setLessonsData: (
      state,
      action: PayloadAction<LessonState['lessonsData']>
    ) => {
      state.lessonsData = action.payload;
    },
    setWork: (state, action: PayloadAction<LessonState['work']>) => {
      state.work = action.payload;
    },
  },
});

export const { setLessonsData, setWork } = lessonsSlice.actions;

export const selectLessons = (state: { lessons: LessonState }) =>
  state.lessons.lessonsData;
export const selectWork = (state: { lessons: LessonState }) =>
  state.lessons.work;

export default lessonsSlice.reducer;
