import { AxiosResponse } from 'axios';
import axiosInstance, { setAxiosDispatch } from '../axiosInstance';
import { AppDispatch } from '../redux/store';
import { GroupResponse, GroupStudentResponse } from './DATA_MODELS';
import { GET_GROUPS_URI, GET_GROUP_MEMBERS_URI } from './URIS';
import {
  setCurrentGroupMembers,
  setGroupList,
} from '../redux/slices/groupsSlice';
import { NavigateFunction } from 'react-router-dom';

export const getGroups = () => async (dispatch: AppDispatch) => {
  setAxiosDispatch(dispatch);
  const response: AxiosResponse<GroupResponse[]> = await axiosInstance.get(
    GET_GROUPS_URI
  );
  if (response) {
    dispatch(setGroupList(response.data));
  }
};

export const getGroupById = (id: string) => async (dispatch: AppDispatch) => {
  setAxiosDispatch(dispatch);
  // TODO fetching logic here
};

export const getGroupMembers =
  (groupId?: string) =>
  async (dispatch: AppDispatch, navigate: NavigateFunction) => {
    if (!groupId) {
      navigate('/');
      console.error('groupId is not provided');
      return;
    }
    setAxiosDispatch(dispatch);
    const response: AxiosResponse<GroupStudentResponse[]> =
      await axiosInstance.get(GET_GROUP_MEMBERS_URI(groupId));
    if (response) {
      dispatch(setCurrentGroupMembers(response.data));
    }
  };
