import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  GroupResponse,
  GroupStudentResponse,
} from '../../services/DATA_MODELS';

interface GroupState {
  groupList: GroupResponse[];
  currentGroupMembers: GroupStudentResponse[];
}

const initialState: GroupState = {
  groupList: [],
  currentGroupMembers: [],
};

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroupList: (state, action: PayloadAction<GroupState['groupList']>) => {
      state.groupList = action.payload;
    },
    setCurrentGroupMembers: (
      state,
      action: PayloadAction<GroupState['currentGroupMembers']>
    ) => {
      state.currentGroupMembers = action.payload;
    },
  },
});

export const { setGroupList, setCurrentGroupMembers } = groupsSlice.actions;

export const selectGroupList = (state: { groups: GroupState }) =>
  state.groups.groupList;
export const selectCurrentGroupMembers = (state: { groups: GroupState }) =>
  state.groups.currentGroupMembers;

export default groupsSlice.reducer;
