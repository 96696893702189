import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface PaginationProps {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const Pagination = ({ page, setPage }: PaginationProps) => {
  return (
    <Box display="flex" alignItems="center">
      <IconButton onClick={() => setPage(page - 1)}>
        <ArrowBackIosNewIcon />
      </IconButton>
      <Typography variant="h6" sx={{ width: 40, textAlign: 'center' }}>
        {page}
      </Typography>
      <IconButton onClick={() => setPage(page + 1)}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default Pagination;
