import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoadingSpinner from './components/systemStatus/LoadingSpinner';
import AlertMessage from './components/systemStatus/AlertMessage';
import { routes } from './pages/ROUTES';

function App() {
  return (
    <BrowserRouter>
      <AlertMessage />
      <LoadingSpinner />
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
