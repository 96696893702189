import { createSlice } from '@reduxjs/toolkit';

export const loadingSpinnerSlice = createSlice({
  name: 'loadingSpinner',
  initialState: {
    isLoading: false,
    eventCount: 0,
  },
  reducers: {
    setLoading: (state, action) => {
      if (action.payload === true) {
        state.eventCount += 1;
        state.isLoading = true;
      } else {
        state.eventCount -= 1;
        if (state.eventCount <= 0) {
          state.isLoading = false;
        }
      }
    },
  },
});

export const { setLoading } = loadingSpinnerSlice.actions;

export const selectIsLoading = (state: any) => state.loadingSpinner.isLoading;

export default loadingSpinnerSlice.reducer;
