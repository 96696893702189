import { Alert, Snackbar } from '@mui/material';
import { useAppDispatch } from '../../redux/hooks';
import {
  AlertState,
  selectSnackbar,
  setIsOpen,
} from '../../redux/slices/systemStatus/alertSlice';
import { useSelector } from 'react-redux';

export default function AlertMessage() {
  const snackbar: AlertState = useSelector(selectSnackbar);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setIsOpen(false));
  };

  return (
    <Snackbar
      open={snackbar?.isOpen}
      autoHideDuration={snackbar?.autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {snackbar?.type === 'error' ? (
        <Alert variant="filled" onClose={handleClose} severity="error">
          {snackbar?.message}
        </Alert>
      ) : snackbar?.type === 'success' ? (
        <Alert variant="filled" onClose={handleClose} severity="success">
          {snackbar?.message}
        </Alert>
      ) : (
        <>No such alert type</>
      )}
    </Snackbar>
  );
}
