import { Box, Grid, Typography } from '@mui/material';

interface Grade {
  grade: number;
  subject: string;
  date: string;
}
interface GradesTableProps {
  grades: Grade[];
}
const GradesTable: React.FC<GradesTableProps> = ({ grades }) => {
  return (
    <Grid container spacing={2}>
      {grades.map((grade, index) => (
        <Grid item key={index}>
          <Box textAlign="center">
            <Typography variant="h5" component="p">
              {grade.grade}
            </Typography>
            <Typography variant="subtitle1">{grade.subject}</Typography>
            <Typography variant="subtitle2">
              {grade.date.substring(5)}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default GradesTable;
