import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, Container, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { postLogin } from '../../services/authService';
import { useAppDispatch } from '../../redux/hooks';
import { paths } from '../ROUTES';
import Header from '../../components/Header';

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener('unauthorized', () => {
      setShowAlert(true);
    });
    return () => {
      document.removeEventListener('unauthorized', () => {
        setShowAlert(true);
      });
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem('AuthHeader') !== null) {
      navigate(paths.dashboard);
    }
  }, [navigate]);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    setShowAlert(false);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setShowAlert(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(postLogin({ username, password }, navigate));
  };

  return (
    <>
      <Header loggedIn={false} />
      <Container maxWidth="sm">
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ marginTop: '40%', marginBottom: '40px' }}
        >
          Prisijungti
        </Typography>

        <form onSubmit={handleSubmit} style={{ marginBottom: '10px' }}>
          <TextField
            label="Prisijungimo vardas"
            value={username}
            onChange={handleUsernameChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Slaptažodis"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            fullWidth
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Prisijungti
          </Button>
        </form>
        {showAlert && (
          <Alert severity="error" onClose={() => setShowAlert(false)}>
            Neteisingas prisijungimo vardas arba slaptažodis
          </Alert>
        )}
      </Container>
    </>
  );
};

export default LoginPage;
