import { Container, Paper, Typography } from '@mui/material';
import LessonsTable from './LessonsTable';
import { useSelector } from 'react-redux';
import { GroupResponse, LessonResponse } from '../../services/DATA_MODELS';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { useEffect, useState } from 'react';
import { getAccountInfo } from '../../services/authService';
import { getLessons } from '../../services/lessonsService';
import { selectLessons } from '../../redux/slices/lessonsSlice';
import Pagination from '../../components/dataControl/Pagination';
import GroupList from './GroupList';
import { getGroups } from '../../services/groupsService';
import { selectGroupList } from '../../redux/slices/groupsSlice';

const TeacherPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [lessonsPage, setLessonsPage] = useState<number>(1);

  useEffect(() => {
    if (localStorage.getItem('AuthHeader') === null) {
      navigate('/login');
    } else {
      dispatch(getAccountInfo());
    }
    dispatch(getLessons(lessonsPage));
    dispatch(getGroups());
  }, [dispatch, navigate, lessonsPage]);

  const lessons: LessonResponse[] = useSelector(selectLessons);
  const groups: GroupResponse[] = useSelector(selectGroupList);

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Pildyti dienyną
        </Typography>
        <GroupList groups={groups} />
      </Paper>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h6">Praeitos pamokos</Typography>
        <LessonsTable lessons={lessons} />
        <Pagination page={lessonsPage} setPage={setLessonsPage} />
      </Paper>
    </Container>
  );
};

export default TeacherPage;
