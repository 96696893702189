import { CircularProgress, Paper, Slide, styled } from '@mui/material';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { selectIsLoading } from '../../redux/slices/systemStatus/loadingSpinnerSlice';

const SpinnerContainer = styled(Paper)`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  position: absolute;
  margin-top: 20px;
  left: 0;
  right: 0;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function LoadingSpinner() {
  const isLoading: boolean = useAppSelector(selectIsLoading);
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout = setTimeout(() => {}, 1000);
    if (isLoading === false) {
      timer = setTimeout(() => setIsActive(isLoading), 200);
    } else {
      clearTimeout(timer);
      setIsActive(isLoading);
    }

    return () => clearTimeout(timer);
  }, [isLoading]);

  return (
    <Slide direction="down" in={isActive} mountOnEnter unmountOnExit>
      <SpinnerContainer elevation={6}>
        <CircularProgress size={26} thickness={5} />
      </SpinnerContainer>
    </Slide>
  );
}
