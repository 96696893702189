import { NavigateFunction } from 'react-router';
import axiosInstance, { setAxiosDispatch } from '../axiosInstance';
import { GET_ACCOUNT_INFO_URI, LOGIN_URI } from './URIS';
import { AppDispatch } from '../redux/store';
import { setAccountInfo } from '../redux/slices/accountInfoSlice';
import { AuthenticationRequest } from './DATA_MODELS';
import { paths } from '../pages/ROUTES';

export const postLogin =
  (authRequest: AuthenticationRequest, navigate: NavigateFunction) =>
  async (dispatch: AppDispatch) => {
    setAxiosDispatch(dispatch);
    const response = await axiosInstance.post(LOGIN_URI, authRequest);
    if (response) {
      localStorage.setItem('AuthHeader', 'Bearer ' + response.data.accessToken);
      navigate(paths.dashboard);
    }
  };

export const getAccountInfo = () => async (dispatch: AppDispatch) => {
  setAxiosDispatch(dispatch);
  const response = await axiosInstance.get(GET_ACCOUNT_INFO_URI);
  if (response) {
    dispatch(setAccountInfo(response.data));
  }
};
