import { AxiosResponse } from 'axios';
import axiosInstance, { setAxiosDispatch } from '../axiosInstance';
import { AppDispatch } from '../redux/store';
import { GradeResponse } from './DATA_MODELS';
import { GET_GRADES_URI } from './URIS';
import { setGradesList } from '../redux/slices/gradesSlice';

export const getGrades = (page: number) => async (dispatch: AppDispatch) => {
  setAxiosDispatch(dispatch);
  const response: AxiosResponse<GradeResponse[]> = await axiosInstance.get(
    GET_GRADES_URI(page)
  );
  if (response) {
    dispatch(setGradesList(response.data));
  }
};
