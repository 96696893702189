import { AccountCircle } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { paths } from '../pages/ROUTES';
import { Link } from 'react-router-dom';

const Header: React.FC<{ loggedIn: boolean }> = ({ loggedIn }) => {
  return (
    <header>
      <AppBar color="transparent" sx={{ background: '#fffaf0' }}>
        <Toolbar>
          <Link to="/">
            <img src="/submark-logo.png" alt="submark-logo" width={50} />
          </Link>
          <Typography variant="h6" sx={{ flexGrow: 1, marginLeft: '20px' }}>
            Korio dienynas
          </Typography>

          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 10 }}
          >
            {loggedIn ? (
              <>
                <Button
                  color="inherit"
                  variant="outlined"
                  href={paths.dashboard}
                >
                  Eiti į dienyną
                </Button>
                <IconButton color="inherit" sx={{ marginLeft: '20px' }}>
                  <AccountCircle />
                </IconButton>
              </>
            ) : (
              <Button color="inherit" variant="outlined" href={paths.login}>
                Prisijungti
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </header>
  );
};

export default Header;
