import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import alertReducer from './slices/systemStatus/alertSlice';
import loadingSpinnerReducer from './slices/systemStatus/loadingSpinnerSlice';
import accountInfoReducer from './slices/accountInfoSlice';
import gradesReducer from './slices/gradesSlice';
import groupsReducer from './slices/groupsSlice';
import lessonsReducer from './slices/lessonsSlice';

export const store = configureStore({
  reducer: {
    loadingSpinner: loadingSpinnerReducer,
    alerts: alertReducer,
    accountInfo: accountInfoReducer,
    grades: gradesReducer,
    groups: groupsReducer,
    lessons: lessonsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
