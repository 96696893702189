import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GradeResponse } from '../../services/DATA_MODELS';

interface GradeState {
  gradesList: GradeResponse[];
}

const initialState: GradeState = {
  gradesList: [],
};

export const gradesSlice = createSlice({
  name: 'grades',
  initialState,
  reducers: {
    setGradesList: (state, action: PayloadAction<GradeState['gradesList']>) => {
      state.gradesList = action.payload;
    },
  },
});

export const { setGradesList } = gradesSlice.actions;

export const selectGradesList = (state: { grades: GradeState }) =>
  state.grades.gradesList;

export default gradesSlice.reducer;
