import React, { useState } from 'react';
import {
  GradeRequest,
  GroupStudentResponse,
} from '../../../services/DATA_MODELS';
import { useSelector } from 'react-redux';
import { selectCurrentGroupMembers } from '../../../redux/slices/groupsSlice';
import { Box, TextField, Typography } from '@mui/material';

interface GradesSectionProps {
  handleGradeChange: (grades: GradeRequest[]) => void;
}

const GradesSection: React.FC<GradesSectionProps> = ({ handleGradeChange }) => {
  const [idsGrades, setIdsGrades] = useState<GradeRequest[]>([]);
  const currentGroupMembers: GroupStudentResponse[] = useSelector(
    selectCurrentGroupMembers
  );

  return (
    <div>
      {currentGroupMembers.map((student, index) => (
        <Box key={index} display="flex" alignItems="center" mt={2}>
          <Typography
            variant="subtitle1"
            component="p"
            sx={{ width: '60%', mr: 2 }}
          >
            {student.name} {student.surname}
          </Typography>
          <TextField
            label="Grade"
            type="number"
            name="grade"
            value={
              idsGrades.find((grade) => grade.studentId === student.studentId)
                ?.value || ''
            }
            onChange={(e) => {
              const newGrades = idsGrades.filter(
                (grade) => grade.studentId !== student.studentId
              );

              if (e.target.value !== '') {
                const addedGrade = {
                  studentId: student.studentId,
                  value: parseInt(e.target.value),
                };

                newGrades.push(addedGrade);
              }

              handleGradeChange(newGrades);
              setIdsGrades(newGrades);
            }}
            sx={{ width: '40%' }}
          />
        </Box>
      ))}
    </div>
  );
};

export default GradesSection;
