import * as React from 'react';
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import OpenIcon from './openIcon.svg';
import styles from './lessonTable.module.css';
import {
  GroupStudentResponse,
  LessonResponse,
} from '../../services/DATA_MODELS';
import { useSelector } from 'react-redux';
import { selectCurrentGroupMembers } from '../../redux/slices/groupsSlice';

interface TableProps {
  lessons: LessonResponse[];
}

const LessonsTable: React.FC<TableProps> = ({ lessons }) => {
  return (
    <Paper sx={{ m: 2 }}>
      {lessons.map((lesson, index) => (
        <LessonRow key={index} lesson={lesson} />
      ))}
    </Paper>
  );
};

interface RowProps {
  lesson: LessonResponse;
}

const LessonRow: React.FC<RowProps> = ({ lesson }) => {
  // const dispatch = useAppDispatch();
  const groupStudents: GroupStudentResponse[] = useSelector(
    selectCurrentGroupMembers
  );

  const [open, setOpen] = useState(false);
  const handleOpen = (groupId: string) => {
    //dispatch;
    //TODO: get somehow the groupId and fetch that group students so that it would be possible to get names next to grades
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'stretch',
        p: 1,
        gap: 2,
      }}
    >
      <Box className={styles.fieldStyle} sx={{ width: '10vw' }}>
        <Typography>{lesson.date}</Typography>
      </Box>
      <Box className={styles.fieldStyle} sx={{ width: '5vw' }}>
        <Typography>{lesson.period}</Typography>
      </Box>
      <Box className={styles.fieldStyle} sx={{ width: '15vw' }}>
        <Typography>{lesson.groupName}</Typography>
      </Box>
      <Box className={styles.fieldStyle} sx={{ width: '15vw' }}>
        <Typography>{lesson.topic}</Typography>
      </Box>
      <Box className={styles.fieldStyle} sx={{ width: '18vw' }}>
        <Typography>{lesson.classwork}</Typography>
      </Box>
      <Box className={styles.fieldStyle} sx={{ width: '20vw' }}>
        <Typography>{lesson.homework}</Typography>
      </Box>
      <Box className={styles.fieldStyle} sx={{ width: '4vw' }}>
        <Button onClick={() => handleOpen(lesson.groupName)}>
          <img width={24} height={24} src={OpenIcon} alt="Open Icon" />
        </Button>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0)',
            },
          },
        }}
      >
        <Box className={styles.modalStyle}>
          <Typography variant="h6">Pažymiai</Typography>
          {lesson.grades?.map((grade, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 1,
                p: 1,
              }}
            >
              <Typography>
                {groupStudents.filter(
                  (student) => student.studentId === grade.studentId
                )[0]?.name || grade.studentId}
                {
                  groupStudents.filter(
                    (student) => student.studentId === grade.studentId
                  )[0]?.surname
                }
              </Typography>
              <Typography>{grade.grade}</Typography>
            </Box>
          ))}
          <Button onClick={handleClose} variant="contained" color="warning">
            Uždaryti
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default LessonsTable;
