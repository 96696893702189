import axios from 'axios';
import { setLoading } from './redux/slices/systemStatus/loadingSpinnerSlice';
import { setErrorMessage } from './redux/slices/systemStatus/alertSlice';

const axiosInstance = axios.create();

let dispatch: any = null;

export const setAxiosDispatch = (dispatchInstance: any) => {
  dispatch = dispatchInstance;
};

axiosInstance.interceptors.request.use(
  (config) => {
    dispatch && dispatch(setLoading(true));

    // Check if AuthHeader exists in localStorage
    const authHeader = localStorage.getItem('AuthHeader');
    if (authHeader) {
      // If AuthHeader exists, add it to the Authorization header
      config.headers['Authorization'] = authHeader;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    dispatch && dispatch(setLoading(false));
    return response;
  },
  (error) => {
    if (
      error.response?.status === 401 &&
      error.response.data.errorMessage === 'Bad credentials.'
    ) {
      document.dispatchEvent(new CustomEvent('unauthorized'));
      dispatch && dispatch(setLoading(false));
      return;
    }

    let message = 'Unknown Error';
    if (error.response) {
      message = error.response.data.message || 'Server error';
    } else if (error.request) {
      message = 'No response was received';
    } else {
      message = error.message;
    }
    dispatch && dispatch(setErrorMessage(message));
    dispatch && dispatch(setLoading(false));
    return;
  }
);

export default axiosInstance;
