import { Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { getAccountInfo } from '../../services/authService';
import { useSelector } from 'react-redux';
import {
  selectAccountData,
  setAccountInfo,
} from '../../redux/slices/accountInfoSlice';
import { AccountResponse } from '../../services/DATA_MODELS';
import styles from './index.module.css';
import TeacherPage from '../TeacherPage';
import StudentPage from '../StudentPage';
import { paths } from '../ROUTES';

export default function DashboardPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (localStorage.getItem('AuthHeader') === null) {
      navigate(paths.login);
    } else {
      dispatch(getAccountInfo());
    }
  }, [dispatch, navigate]);

  const accountInfo: AccountResponse = useSelector(selectAccountData);

  return (
    <div>
      <header className={styles.header}>
        <img
          className={styles.submarkLogo}
          src="/submark-logo.png"
          alt="submark-logo"
        />
        <Typography variant="h4" component="h1" gutterBottom>
          Labas, {accountInfo?.name} {accountInfo?.surname}!
        </Typography>
        <Typography variant="h5">
          {' '}
          Jūs esate{' '}
          {accountInfo?.roles?.map((role) => (
            <div key={role}>{role} </div>
          ))}{' '}
          (Pasirinkta pirma rolė sąraše)
        </Typography>
        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          onClick={() => {
            localStorage.removeItem('AuthHeader');
            dispatch(setAccountInfo({ roles: [], name: '', surname: '' }));
            navigate('/login');
          }}
        >
          Atsijungti
        </Button>
      </header>
      {accountInfo?.roles && accountInfo?.roles[0] === 'TEACHER' ? (
        <TeacherPage />
      ) : accountInfo?.roles && accountInfo?.roles[0] === 'STUDENT' ? (
        <StudentPage />
      ) : (
        <h2>
          NO IMPLEMENTATION FOR ROLE "
          {(accountInfo?.roles && accountInfo?.roles[0]) || '???'}"
        </h2>
      )}{' '}
    </div>
  );
}
