import DashboardPage from './DashboardPage';
import LoginPage from './LoginPage';
import NewLesson from './TeacherPage/NewLesson';
import HomePage from './HomePage';

export const paths = {
  login: '/login',
  dashboard: '/dashboard',
  newLesson: '/new-lesson/:groupdId',
  home: '/',
  any: '*',
};

export interface IRoute {
  path: string;
  title: string;
  element: JSX.Element;
}

export const routes: IRoute[] = [
  {
    path: paths.login,
    title: 'Login',
    element: <LoginPage />,
  },
  {
    path: paths.dashboard,
    title: 'Home Page',
    element: <DashboardPage />,
  },
  {
    path: paths.newLesson,
    title: 'New Lesson',
    element: <NewLesson />,
  },
  {
    path: paths.home,
    title: 'Home Page',
    element: <HomePage />,
  },
  {
    path: paths.any,
    title: 'Not Found',
    element: (
      <h1>
        Not Found. <a href="/">Go home</a>
      </h1>
    ),
  },
];
