// Auth endpoint
export const LOGIN_URI: string = 'https://api.koriodienynas.lt/auth/login';
export const GET_ACCOUNT_INFO_URI: string =
  'https://api.koriodienynas.lt/auth/account';

// Grades endpoints
export const GET_GRADES_URI = (page: number): string =>
  `https://api.koriodienynas.lt/grades?page=${page}`;

// Groups endpoints
export const GET_GROUPS_URI: string = 'https://api.koriodienynas.lt/groups';
export const GET_GROUP_BY_ID_URI = (id: string): string =>
  `https://api.koriodienynas.lt/groups/${id}`;
export const GET_GROUP_MEMBERS_URI = (id: string): string =>
  `https://api.koriodienynas.lt/groups/${id}/members`;

// Lessons endpoints
export const GET_LESSONS_URI = (page: number): string =>
  `https://api.koriodienynas.lt/lessons?page=${page}`;
export const POST_LESSON_URI: string =
  'https://api.koriodienynas.lt/api/lessons';
export const GET_LESSONS_WORK_URI = (page: number): string =>
  `https://api.koriodienynas.lt/lessons/work?page=${page}`;
