import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/hooks';
import { getGroupMembers } from '../../../services/groupsService';
import {
  GradeRequest,
  GroupResponse,
  LessonRequest,
} from '../../../services/DATA_MODELS';
import GradesSection from './GradesSection';
import { postLesson } from '../../../services/lessonsService';
import { useSelector } from 'react-redux';
import { selectGroupList } from '../../../redux/slices/groupsSlice';

const NewLesson: React.FC = () => {
  const navigate = useNavigate();
  const { groupId } = useParams<{ groupId: string }>();
  const [lessonRequest, setLessonRequest] = useState<LessonRequest>({
    date: '',
    period: 0,
    topic: '',
    classwork: '',
    homework: '',
    groupId: '',
    grades: [],
  });

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (groupId) {
      setLessonRequest({ ...lessonRequest, groupId: groupId });
    } else {
      navigate('/');
      console.error('groupId is not provided');
    }
  }, [groupId, lessonRequest, navigate]);

  useEffect(() => {
    dispatch(getGroupMembers(groupId));
  }, [dispatch, groupId]);

  const groupNames: GroupResponse[] = useSelector(selectGroupList);

  const handleLessonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLessonRequest({
      ...lessonRequest,
      [event.target.name]: event.target.value,
    });
  };

  const handleGradeChange = (grades: GradeRequest[]) => {
    setLessonRequest({ ...lessonRequest, grades: grades });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(postLesson(lessonRequest, navigate));
  };

  return (
    <Container maxWidth="lg">
      <h1>Nauja pamoka</h1>
      <h2>
        Grupė: {groupNames.find((group) => group.groupId === groupId)?.name}
      </h2>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <TextField
              label="Data"
              type="date"
              name="date"
              value={lessonRequest.date}
              onChange={handleLessonChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Kelinta pamoka"
              type="number"
              name="period"
              value={lessonRequest.period || ''}
              onChange={handleLessonChange}
              fullWidth
            />
            <TextField
              label="Tema"
              name="topic"
              value={lessonRequest.topic}
              onChange={handleLessonChange}
              fullWidth
            />
            <TextField
              label="Klasės darbas"
              name="classwork"
              value={lessonRequest.classwork}
              onChange={handleLessonChange}
              fullWidth
            />
            <TextField
              label="Namų darbas"
              name="homework"
              value={lessonRequest.homework}
              onChange={handleLessonChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">Pažymiai:</Typography>
            <GradesSection handleGradeChange={handleGradeChange} />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                navigate('/');
              }}
              sx={{ marginRight: '10px' }}
            >
              Grįžti
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Pateikti
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default NewLesson;
