import { AxiosResponse } from 'axios';
import axiosInstance, { setAxiosDispatch } from '../axiosInstance';
import { setLessonsData, setWork } from '../redux/slices/lessonsSlice';
import { AppDispatch } from '../redux/store';
import {
  LessonRequest,
  LessonResponse,
  LessonWorkResponse,
} from './DATA_MODELS';
import { GET_LESSONS_URI, GET_LESSONS_WORK_URI, POST_LESSON_URI } from './URIS';
import { NavigateFunction } from 'react-router-dom';
import { setSuccessMessage } from '../redux/slices/systemStatus/alertSlice';

export const getLessons = (page: number) => async (dispatch: AppDispatch) => {
  setAxiosDispatch(dispatch);
  const response: AxiosResponse<LessonResponse[]> = await axiosInstance.get(
    GET_LESSONS_URI(page)
  );
  if (response) {
    dispatch(setLessonsData(response.data));
  }
};

export const postLesson =
  (lesson: LessonRequest, navigate: NavigateFunction) =>
  async (dispatch: AppDispatch) => {
    setAxiosDispatch(dispatch);
    const response = await axiosInstance.post(POST_LESSON_URI, lesson);
    if (response) {
      navigate('/');
      dispatch(setSuccessMessage('Pamokos informacija sėkmingai įrašyta'));
    }
  };

export const getLessonsWork =
  (page: number) => async (dispatch: AppDispatch) => {
    setAxiosDispatch(dispatch);
    const response: AxiosResponse<LessonWorkResponse[]> =
      await axiosInstance.get(GET_LESSONS_WORK_URI(page));
    if (response) {
      dispatch(setWork(response.data));
    }
  };
