import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AlertState {
  isOpen: boolean;
  message: string;
  type: 'error' | 'success';
  autoHideDuration: number;
}

const initialState: AlertState = {
  isOpen: false,
  message: "This message shouldn't be here",
  type: 'error',
  autoHideDuration: 3000,
};

export const alertSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setErrorMessage: (state, action: PayloadAction<AlertState['message']>) => {
      state.type = 'error';
      state.isOpen = true;
      state.message = action.payload;
    },
    setSuccessMessage: (
      state,
      action: PayloadAction<AlertState['message']>
    ) => {
      state.type = 'success';
      state.isOpen = true;
      state.message = action.payload;
      console.log('here');
    },
    setIsOpen: (state, action: PayloadAction<AlertState['isOpen']>) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setErrorMessage, setSuccessMessage, setIsOpen } =
  alertSlice.actions;

export const selectSnackbar = (state: { alerts: AlertState }) => state.alerts;

export default alertSlice.reducer;
