import { Box, Container, CssBaseline, Typography } from '@mui/material';
import Header from '../../components/Header';

const HomePage: React.FC = () => {
  const loggedIn: boolean = localStorage.getItem('AuthHeader') !== null;
  const background = {
    minHeight: '100vh',
    background: 'linear-gradient(45deg, #ffb347 50%, #ff8c00 50%)',
  };

  return (
    <div style={background}>
      <CssBaseline />
      <Header loggedIn={loggedIn} />
      <main>
        <Container
          maxWidth="lg"
          sx={{ textAlign: 'center', padding: '100px 0' }}
        >
          <Typography
            variant="h2"
            color="textPrimary"
            fontWeight="fontWeightBold"
            sx={{ mb: 3, mt: 8 }}
          >
            Patogesnis kelias į mokyklą jau tiesiamas!{' '}
          </Typography>
          <Typography variant="h6" color="textSecondary" sx={{ margin: 4 }}>
            <strong>Korio dienyno</strong> kuriama ateitis ne už kalnų. Traukia
            dėmesį? Susisiekite!
          </Typography>
          <Box sx={{ '& > *': { mx: 1 } }}>
            <Typography variant="h4" color="textSecondary" sx={{ mt: 10 }}>
              Kontaktai
            </Typography>
            <Typography
              variant="h6"
              color="textPrimary"
              fontWeight="fontWeightBold"
              sx={{ mt: 4 }}
            >
              naglis.suliokas@gmail.com
            </Typography>
          </Box>
        </Container>
        {/* <Footer /> */}
      </main>
    </div>
  );
};

export default HomePage;
