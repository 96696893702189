import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './work.table.css';
import { LessonWorkResponse } from '../../services/DATA_MODELS';

interface TableProps {
  work: LessonWorkResponse[];
}

const WorkTable: React.FC<TableProps> = ({ work }) => {
  return (
    <Paper sx={{ m: 2 }}>
      {work.map((w, index) => (
        <TableRow key={index} work={w} />
      ))}
    </Paper>
  );
};

interface RowProps {
  work: LessonWorkResponse;
}

const TableRow: React.FC<RowProps> = ({ work }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'stretch',
        p: 1,
        gap: 2,
      }}
    >
      <Box className="fieldStyle" sx={{ width: '10vw' }}>
        <Typography>{work.date}</Typography>
      </Box>
      <Box className="fieldStyle" sx={{ width: '15vw' }}>
        <Typography>{work.topic}</Typography>
      </Box>
      <Box className="fieldStyle" sx={{ width: '18vw' }}>
        <Typography>{work.classwork}</Typography>
      </Box>
      <Box className="fieldStyle" sx={{ width: '20vw' }}>
        <Typography>{work.homework}</Typography>
      </Box>
    </Box>
  );
};

export default WorkTable;
