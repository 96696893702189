import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AccountResponse } from '../../services/DATA_MODELS';

interface AccountState {
  accountData: AccountResponse;
}

export const accountInfoSlice = createSlice({
  name: 'accountInfo',
  initialState: {
    accountData: {},
  },
  reducers: {
    setAccountInfo: (
      state,
      action: PayloadAction<AccountState['accountData']>
    ) => {
      state.accountData = action.payload;
    },
  },
});

export const { setAccountInfo } = accountInfoSlice.actions;

export const selectAccountData = (state: { accountInfo: AccountState }) =>
  state.accountInfo.accountData;

export default accountInfoSlice.reducer;
