import { useState, useEffect } from 'react';
import { Container, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { getAccountInfo } from '../../services/authService';
import { GradeResponse, LessonWorkResponse } from '../../services/DATA_MODELS';
import { useSelector } from 'react-redux';
import WorkTable from './WorkTable';
import { selectWork } from '../../redux/slices/lessonsSlice';
import { getLessonsWork } from '../../services/lessonsService';
import Pagination from '../../components/dataControl/Pagination';
import GradesTable from './GradesTable';
import { selectGradesList } from '../../redux/slices/gradesSlice';
import { getGrades } from '../../services/gradesService';

const StudentPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [gradesPage, setGradesPage] = useState<number>(1);
  const [workPage, setWorkPage] = useState<number>(1);

  useEffect(() => {
    if (localStorage.getItem('AuthHeader') === null) {
      navigate('/login');
    } else {
      dispatch(getAccountInfo());
    }
    dispatch(getLessonsWork(workPage));
    dispatch(getGrades(gradesPage));
  }, [dispatch, navigate, workPage, gradesPage]);

  const work: LessonWorkResponse[] = useSelector(selectWork);
  const grades: GradeResponse[] = useSelector(selectGradesList);

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Paskutiniai pažymiai
        </Typography>
        <GradesTable grades={grades} />
        <Pagination page={gradesPage} setPage={setGradesPage} />
      </Paper>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Paskutinės pamokos
        </Typography>
        <WorkTable work={work} />
        <Pagination page={workPage} setPage={setWorkPage} />
      </Paper>
    </Container>
  );
};

export default StudentPage;
